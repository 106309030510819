
<template>
  <div id="carousel">
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
  </div>


  <!-- <div id="container">
        
        <div class="center slider">
          
            <div class="card" v-for="item in items">
             
              <div class="thumbnail">
      <img class="card-img" :src="item.image" />
    </div>
            </div>

        </div>
        
    </div>  -->
</template>

<script>
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import { animate, glide, spring } from "motion"


export default {
  name: 'Slider',
  mounted() {
    this.$nextTick(function () {
      animate(
  ".item",
  { scale: 1.1, opacity: 1 },
  { easing: spring() }
)

  /*     animate(
        "#carousel",
        { x: 0 },
       // { easing: glide({ velocity: -500 }) }
        { easing: spring() }
      ) */
    })
    /*     $(".center").slick({
            dots: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 3,
            touchThreshold:25,
            slidesToScroll: 1,
            autoplaySpeed: 2000,
            centerPadding:'24px'
            }); */
  },
  data() {
    return {
    }
  },
  props: {
    items: Array
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
#carousel {
  --white: #f5f5f5;
  --black: #0f1115;
  --yellow: #FFEB0E;
  --strong-blue: #0d63f8;
  --blue: #31a6fa;
  --green: #57eb64;
  --pink: #ff2965;
  --red: #ff1231;
  --splash: #00ffdb;
  --feint: rgba(133, 150, 193, 0.1);

  --background: var(--black);
  --foreground: var(--white);
  --primary: var(--yellow);
  --secondary: var(--strong-blue);
  --success: var(--green);
  --error: var(--red);

}

#carousel {
  display: flex;
}

.item {
  width: 100px;
  height: 140px;
  border-radius: 10px;
  background-color: var(--splash);
  flex-shrink: 0;
  margin-right: 10px;
}

.item:nth-child(3n) {
  background-color: var(--yellow);
}

.item:nth-child(3n + 1) {
  background-color: var(--pink);
}
</style>

